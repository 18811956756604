import React, { useState } from "react"
import axios from 'axios'
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import GridLayout, { GridItem } from '../components/utils/GridLayout'
import Select from '../components/utils/SelectComponent'
import Animation from '../components/utils/Animation'

import style from './style/contact.module.scss'

import { updateState } from '../functions'

import contactAnimation from '../animations/contact.json'
import contact2Animation from '../animations/contact2.json'


const ENDPOING = 'https://getform.io/f/e2c9d00d-1586-46d8-920e-07a9534fcb3a';

/**
 * Download a file
 * @param  {String} data     [description]
 * @param  {[type]} fileName [description]
 * @param  {String} type     [description]
 * @return {[type]}          [description]
 */
function downloadFile(fileName) {
  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";

  // Use download attribute to set set desired file name
  a.setAttribute('href', '/' + fileName);
  a.setAttribute("download", fileName);

  // Append
  document.body.appendChild(a);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  document.body.removeChild(a);
}

/**
 * Submit form to mailchimp
 * @param  {Object} options.state    Form data - component state
 * @param  {function} options.setState State modifier
 * @return {function}                  onsubmit event handler
 */
function onFormSubmit({state,setState}, hooks) {
	return (evt) => {
		evt.preventDefault();

		hooks.beforeSend();

		axios.post(
			ENDPOING,
			state
		).then(({data}) => {
			hooks.res(null);
		}).catch((err) => ( hooks.res(err) ))
	}
}


const ContactPage = () => {
	const { allPressKitYaml } = useStaticQuery(graphql`
		{
	  	allPressKitYaml {
      	nodes {
	        name
	        label
	        required
	        type
	      }
	    }
  	}
 	`)

	const [ state, setState ] = useState({});
	const [ domState, setDomState ] = useState({});

	return (
	  <Layout>

	  	<div id = { style.Contact } >
	  		<SEO title="Contact" />
		  	<GridLayout>
		  		<GridItem grid = {{
		  			col: { md: 10, lg: 8 },
		  			push: { md: 1, lg: 2 },
		  		}}>
		  			<h1 className = 'title title--h3'>
		  				Press kit
		  			</h1>
		  			<h2 className = 'title title--pages'>
		  				Please fill out the form
		  			</h2>

		  			{ domState.success ? <p className = { style.success }>Done.</p> : null }
		  			{ domState.error ? <p className = { style.error }>{ domState.error }</p> : null }

		  			<form 
		  				action = { ENDPOING } 
		  				method="post" 
		  				onSubmit = { onFormSubmit({state,setState}, {
		  					res: function(err) {
			  					if ( err ) {
			  						setDomState({ error: 'An error occured.' });
			  					} else {
			  						setDomState({ success: true });
			  						setState({});
			  						downloadFile('press-kit.pdf');
			  					}
			  				},
			  				beforeSend: function() {
			  					setDomState({ isLoading: true, error: null });
			  				}
		  				}) }
		  				className = { style.ContactForm }>
		  				{
		  					allPressKitYaml.nodes.map((node) => {
		  						const { id, label, required, options } = node;
		  						let job = null;

		  						if ( options && state.job ) {
		  							job = options.find(({label}) => ( label === state.job ));
		  						}

		  						switch(node.type) {
		  							case 'text':
		  							case 'email':
		  							default: 
		  								return (
		  									<div key = { id }>
		  										<input 
		  											value = { state[node.name] ? state[node.name] : '' }
			  										onChange = { updateState(setState, state) }
			  										placeholder = { required ? label + '*' : label } 
			  										className = { `${style.input} ${style.input__text}`}
			  										{ ...node } />
			  								</div>
			  							)
		  						}
		  					})
		  				}
		  				<div className = { style.ContactForm__footer }>
		  					<p>* required fields</p>
		  					<button 
		  						disabled = { domState.isLoading }
		  						type = 'Submit'
		  						className = 'button button--blue'>
		  						Download
		  					</button>
		  				</div>
		  			</form>

		  		</GridItem>
		  	</GridLayout>

		  	<Animation
		  	  id = { style.Animation }
		  	  animationData = { contactAnimation }
		  	  width = "100%"
		  	  height = "auto" />

		  	<Animation
		  	  id = { style.Animation2 }
		  	  animationData = { contact2Animation }
		  	  width = "100%"
		  	  height = "auto" />
		  </div>
	  </Layout>
	)
}

export default ContactPage
